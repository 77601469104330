@import 'overwrite.css';

:root {
  --content-width: 1280px;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: Ubuntu;
  src: local('Ubuntu');
  src: url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.eot);
  src:
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.eot#iefix) format('embedded-opentype'),
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.woff) format('woff'),
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.ttf) format('truetype'),
    url(https://s91588.cdn.ngenix.net/fonts/ubuntu-bold.svg#ubuntu-bold) format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Rooftop;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Rooftop-Regular.1e4819ce.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Rooftop2';
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Rooftop-Bold.e84f9a30.otf) format('opentype');
}

@font-face {
  font-family: 'Halvar Breit';
  font-style: normal;
  font-weight: 800;
  src: url(./fonts/HalvarBreit-ExtraBold.15d4d2bf.otf) format('opentype');
}

[data-theme='t2'] {
  --font-open-sans: 'Rooftop', sans-serif;
  --font-aeroport: 'Halvar Breit';
  --color-background: #fff;
  --color-L100: #fff;
  --color-D100: #171d23;
  --color-D80: #002a3a;
  --color-D60: #636f7d;
  --color-D50: #707b88;
  --color-D40: #7e8894;
  --color-D30: #99a1ab;
  --color-D20: #b3bac3;
  --color-D15: #dadde2;
  --color-D10: #f5f5f5;
  --color-D06: #f6f5f4;
  --color-G300: #11693f;
  --color-G200: #0f9d44;
  --color-G100: #0dd149;
  --color-G30: #c3f4d3;
  --color-G06: #eefcf2;
  --color-B300: #002a3a;
  --color-B200: #0685c3;
  --color-B100: #00afff;
  --color-B30: #b6e6fc;
  --color-B06: #f0faff;
  --color-O300: #725122;
  --color-O200: #b97311;
  --color-O100: #ff9500;
  --color-O30: #ffe1b8;
  --color-O06: #fff9f0;
  --color-V300: #2c3278;
  --color-V200: #3e3da7;
  --color-V100: #5047d7;
  --color-V30: #cbc9f3;
  --color-V06: #f3f2fc;
  --color-R300: #59212d;
  --color-R200: #8d1f25;
  --color-R100: #c11d1d;
  --color-R30: #f4bebe;
  --color-R06: #fdf2f2;

  [data-qa='Spinner'] {
    --color-B100: var(--color-D100);
  }
}

.bold {
  font-weight: 700;
}
